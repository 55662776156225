<template>
  <page-content id="new-event-forms-view">
    <v-card>
      <v-card-text>
        <validation-observer v-slot="{ handleSubmit }">
          <v-form @submit.prevent="handleSubmit(validateForm)">
            <app-toolbar>
              <v-checkbox v-if="!isEdit" v-model="expert" class="mr-3" label="Wszytkie opcje"></v-checkbox>
              <v-checkbox v-if="!isEdit && expert" v-model="toHandle" class="mr-3" label="Do obsłużenia"></v-checkbox>
              <v-checkbox v-if="!isEdit && expert && false" v-model="notify" class="mr-3"
                label="Powiadom właściciela"></v-checkbox>
            </app-toolbar>
            <v-row>
              <v-expand-transition>
                <v-col v-if="notes.length > 0 || events.data.count > 0" cols="12">
                  <v-alert v-for="(event, i) in events.data.results" :key="i" prominent text color="red">
                    <router-link class="text-decoration-none" :to="{ name: 'Event', params: { id: event.id } }">
                      <v-row dense align="center">
                        <v-col cols="4" md="3" align="right">
                          <span class="
                              d-flex
                              flex-row
                              align-center
                              v-alert--prominent
                            ">
                            <v-icon class="mr-3">
                              mdi-send-outline
                            </v-icon>
                            <event-time :event="event"></event-time>
                          </span>
                        </v-col>
                        <v-col cols="7" md="6">
                          <span class="v-card-subtitle">
                            {{ event.code | codeAsString }}</span>
                        </v-col>
                        <v-col cols="2" md="3">
                          <task-status-all v-if="taskTree(event.tasks) != null"
                            :task="taskTree(event.tasks)"></task-status-all>
                        </v-col>
                      </v-row>
                    </router-link>
                  </v-alert>
                  <site-notes class="mt-4" :notes="notes"></site-notes>
                </v-col>
              </v-expand-transition>
            </v-row>
            <v-row>
              <v-col cols="12">
                <sites-combo :site="event.site" @site-selected="siteSelected" autofocus>
                </sites-combo>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <event-editor :event="event" :autocommit="true" :expert="expert">
                </event-editor>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <users-combo :usersSelected="reported" :disabled="event.site == null" @user-selected="reportedSelected"
                  :label="$t('user.reported')" :multiple="false" :users="siteMembers"></users-combo>
              </v-col>
            </v-row>

            <v-row v-if="!isEdit && toHandle">
              <v-col cols="12">
                <users-select :user="patrolTask.user" @user-selected="patrolSelected" label="user.patrol"
                  :users="fetchPatrols()"></users-select>
              </v-col>
              <v-col cols="12" v-if="expert">
                <v-text-field dense v-model="patrolTask.description" :label="$t('patrolTask.description')"
                  hide-details="false" outlined></v-text-field>
                <v-checkbox v-model="canClose" class="mr-3" label="Patrol może odjechać bez pozwolenia"></v-checkbox>
              </v-col>
              <v-col cols="12" v-if="expert">

              </v-col>
            </v-row>
            <app-card-actions>
              <cancel-btn @click="$router.back()" />
              <apply-btn :disabled="!validate" :loading="saving" type=submit />
            </app-card-actions>
          </v-form>
        </validation-observer>
      </v-card-text>
    </v-card>
    <material-snackbar v-model="snackbar.visible" :type="snackbar.color" :timeout="snackbar.timeout" top center>
      <!-- <site-notes :notes="notes" deletable></site-notes> -->
      <!-- Welcome to <span class="font-weight-bold">&nbsp;MATERIAL DASHBOARD PRO&nbsp;</span> — a beautiful admin panel for every web developer. -->
      <!-- {{ snackbar.message }} -->
    </material-snackbar>
  </page-content>
</template>

<script>
import _ from "lodash"
import { UserTaskType, taskTree } from "@/store/modules/task"
import EventService from "@/services/event.service"
import UserService from "@/services/user.service"
import TaskService from "@/services/task.service"
import SiteService from "@/services/site.service"
import EventEditor from "../components/EventEditor.vue"
import { sync } from "vuex-pathify"

export default {
  components: { EventEditor },
  name: "NewEventFormsView",

  beforeMount() {
    this.expert = this.$route.params?.expert
    const event = this.$route.params.event
    if (event) {
      this.event = event
      this.reported = this.event.details.reported_by
    } else if (this.$route.params.eventId) {
      EventService.fetch(this.$route.params.eventId).then(response => {
        console.log("----- fetch event ", response.data)
        this.event = response.data
      })
    }
    // generator
    // this.eventGenerator = setInterval(function() {
    //     var code = this.codes[Math.floor(Math.random() * this.codes.length)];
    //     var site = Math.floor(Math.random() * 12500)
    //     console.log("Event generator", code.code, "siteid", site)
    //     EventService.create(site, {
    //       code: code.code,
    //       details: {},
    //       source: {
    //         collector:'event-generator',
    //         raw_data: '',
    //         parsed_data: {'createdBy': 'automat'}
    //       }
    //     })
    //   }.bind(this), 500);
  },
  beforeDestroy() {
    // generator
    // clearInterval(this.eventGenerator)
  },
  computed: {
    isEdit() {
      return this.event.id > 0
    },
    validate() {
      return this.event.site != null && this.event.code != null
    },
    user: sync("user.profile")
  },
  data: () => ({
    // generator
    // eventGenerator: null,
    expert: false,
    saving: false,
    toHandle: true,
    canClose: false,
    notify: true,
    snackbar: {
      visible: false,
      color: "info",
      message: "Info message",
      timeout: 3000
    },
    siteMembers: new Promise(resolve => {
      return resolve([])
    }),
    reported: null,
    event: {
      id: null,
      createdAt: new Date(),
      dateTime: new Date("2019-01-02 10:00:00"),
      time: new Date().toLocaleTimeString().substring(0, 5),
      date: new Date().toISOString().substring(0, 10),
      code: { code: '130.1', text: 'Alarm włamaniowy' }, //TODO remove default
      site: null,
      details: { info: "" }
    },
    events: {
      data: [],
      loading: false
    },
    notes: [],
    patrolTask: {
      user: null,
      description: "",
      type: 2
    }
  }),
  watch: {
    reported() {
      console.log("this.reported :>> ", this.reported)
    }
  },
  methods: {
    taskTree,
    fetchPatrols() {
      return UserService.fetchPatrols().then(res => res.results)
    },
    siteSelected(site) {
      this.event.site = site
      if (site != null) {
        SiteService.getNotes(site.id).then(response => {
          // this.notes = response.data
          this.notes = response.data
          // for (const note of response.data) {
          //   this.$dialog.notify.info(note.content.message, {
          //     timeout: 10000
          //   })
          // }
        })
        this.events.loading = true
        EventService.fetchSiteEvents(site.id, { state: "open" }).then(
          response => {
            this.events.data = response.data
            this.events.loading = false
          }
        )
        this.siteMembers = new Promise(resolve => {
          SiteService.fetchMembers(site.id).then(data => {
            let users = []
            let selected = null;
            for (const record of data) {
              users.push(record.user)
              if (record.user.id == this.event?.details?.reported_by?.id) {
                selected = record.user;
              }
            }
            resolve(users)
            this.reported = selected;
          })
        })
      }
    },
    patrolSelected(user) {
      console.log("set patrol ", user)
      this.patrolTask.user = user
    },
    reportedSelected(member) {
      console.log("set reported ", member)
      if (_.isString(member)) {
        member = { id: 0, full_name: member }
      }
      this.reported = member
    },
    validateForm() {
      if (this.saving) {
        return
      }
      this.saving = true
      if (this.event?.id) {
        if (this.reported != null) {
          this.event.details.reported_by = {
            id: this.reported.id,
            full_name: this.reported.full_name
          }
        } else {
          console.log("reported is null :>> ")
          this.event.details.reported_by = null
        }
        const event = {
          id: this.event?.id,
          created_at: this.event.created_at,
          code_id: this.event.code.id,
          site_id: this.event.site.id,
          code: this.event.code.code,
          details: this.event.details
        }
        return EventService.update(event)
          .then(() => {
            this.$router.back()
          })
          .catch(err => {
            console.error(err)
            this.snackbar.message = err
            this.snackbar.color = "error"
            this.snackbar.visible = true
            this.saving = false
          })
      } else {
        let details = {}
        return EventService.create({
          created_at: this.event.created_at,
          siteId: this.event.site.id,
          code: this.event.code.code,
          info: this.event.details.info,
          userReported: this.reported
        })
          .then(response => {
            const eventId = response.data.id
            if (!this.toHandle) {
              this.$router.push({ name: "Events" })
              this.saving = false
              return
            }
            TaskService.create(eventId, {
              assignees: [
                {
                  user_id: this.user.id
                }
              ],
              task_type: UserTaskType.DISPATCHER,
              description: "",
              details: details
            }).then(response => {
              console.log("Dispatcher response", response)

              let tasks = []
              console.log("patrol task: ", this.patrolTask)
              this.patrolTask?.user?.forEach(user => {
                tasks.push(
                  TaskService.create(eventId, {
                    assignees: [
                      {
                        user_id: user.id
                      }
                    ],
                    parent_id: response.data.id,
                    task_type: this.patrolTask.type,
                    description: this.patrolTask.description,
                    details: {
                      options: { can_closed: this.canClose }
                    }
                  }).then(response => {
                    console.log("Patrol response", response)
                  })
                )
              })
              console.log("tasks to add", tasks)
              Promise.all(tasks)
                .then(() => this.$router.push({ name: "Events" }))
                .catch(err => {
                  console.error(err)
                  this.saving = false
                  this.snackbar.message = err
                  this.snackbar.color = "error"
                  this.snackbar.visible = true
                })
            })
          })
          .catch(err => {
            console.error(err)
            this.snackbar.message = err
            this.snackbar.color = "error"
            this.snackbar.visible = true
            this.saving = false
          })
      }
    }
  }
}
</script>

